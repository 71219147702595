import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './views/Home'
import Confirm from './views/Confirm'
// import Test from './views/Test'
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/confirm" element={<Confirm/>}></Route>
      {/* <Route path="/Test" element={<Test/>}></Route> */}
    </Routes>
  );
}

export default App;

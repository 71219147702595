import axios from 'axios';

const apiURL = 'https://rtn.bigstream.cloud/db/twdata_'
const appApiURL = 'https://rtn.bigstream.cloud/appapi'

async function getDataTw_Old(appName, expertName){
    // let headers = {
    //     'Content-Type':'application/json',
    //     'Access-Control-Allow-Origin':"*"
    // }
    let data_tw = await axios({ method:'GET', url: `${apiURL}${appName}?__limit=1&__sort=-created_ts&expert_name__nin=${expertName}&expert_skip__nin=${expertName}&flag__nin=rt` })
    return data_tw
}

async function getDataTw(appName, expertName){
    let data_tw = await axios({ method:'GET', url: `${appApiURL}/app/${appName}/sampling_tw?expname=${expertName}` })
    return data_tw
}

async function getCountConfirmByExpert_Old(appName, expertName){
    let data_count_list_1 = await axios({ method:'GET', url: `${apiURL}${appName}?__sort=-created_ts&expert_name__in=${expertName}` })
    let data_count_list_2 = await axios({ method:'GET', url: `${apiURL}${appName}?__sort=-created_ts&expert_skip__in=${expertName}` })

    if(data_count_list_1.status !== 200){
        return { status : data_count_list_1.status }
    }
    if(data_count_list_2.status !== 200){
        return { status : data_count_list_2.status }
    }

    return { status : 200, count : data_count_list_1.data.length + data_count_list_2.data.length }
}

async function getCountConfirmByExpert(appName, expertName){
    let data_count_list = await axios({ method:'GET', url: `${appApiURL}/app/${appName}/stats?expname=${expertName}` })

    if(data_count_list.status !== 200){
        return { status : data_count_list.status }
    }

    return { status : 200, count : data_count_list.data.label }
}

async function updateExpert_Old(appName, id, expert_name , status){
    let data_tw = await axios({ method:'GET', url: `${apiURL}${appName}/${id}` })

    if(data_tw.status !== 200){
        return data_tw // error ?
    }

    let data_for_update = {}
    let expert_name_list = []
    let expert_label_list = []
    let expert_skip_list = []

    if(data_tw.data.expert_name){
        expert_name_list = data_tw.data.expert_name
    }
    if(data_tw.data.expert_label){
        expert_label_list = data_tw.data.expert_label
    }
    if(data_tw.data.expert_skip){
        expert_skip_list = data_tw.data.expert_skip
    }

    if(status === 0 || status === 1){
        expert_name_list.push(expert_name)
        expert_label_list.push(status)

        data_for_update = {
            expert_name: expert_name_list,
            expert_label: expert_label_list,
        }

    }
    else{
        expert_skip_list.push(expert_name)
        data_for_update = {
            expert_skip: expert_skip_list,
        }
    }

    let data_update = await axios({ method:'PATCH', url: `${apiURL}${appName}/${id}`, data: data_for_update })
    return data_update
}

async function updateExpert(appName, id, expert_name , status){
    let data_for_update = {}

    data_for_update.id = id
    data_for_update.expname = expert_name
    data_for_update.label=null

    if(String(status).length==3){
        data_for_update.label = String(status)
    }

    let data_update = await axios({ method:'PUT', url: `${appApiURL}/app/${appName}/label`, data: data_for_update })
    return data_update
}

export { 
    getDataTw, getCountConfirmByExpert, updateExpert
}

import React , { useEffect , useState } from "react";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
function Home(){
    const [dropdownAppName, setDropdownAppName] = useState([]);
    const [expertName, setExpertName] = useState("");
    const [errorExperName, setErrorExperName] = useState("");
    const [titleAppName, setTitleAppName] = useState("");
    let navigate = useNavigate(); 

    useEffect(() => {  
        async function actionStart() {
            setTitleAppName("dengue")
            setDropdownAppName(genDropdownAppName());
        }       
        actionStart()
    } , []);

    function genDropdownAppName(){
        let options = []
        options.push({value: "dengue" , label: "Dengue"})
        options.push({value: "test" , label: "Test"})
        return <Select options={options} defaultValue={options.length > 0 ? options[0] : ""} onChange={(event) => activeDropDownAppName(event.value) } />
    }

    function activeDropDownAppName(value){
        setTitleAppName(value)
    }

    function handleSubmit(e) {
        e.preventDefault();
        let regex = (/^[a-zA-Z0-9)]+$/).test(expertName)
        if(regex){
            navigate('/confirm', { state: { titleAppName : titleAppName, expertName: expertName } });
        }
        setErrorExperName("Invalid exper name")
      }

    return (
        <div className="container" style={{padding: '30px'}}>
          <br/>
          <div className="row">
            <div className="col">
                <h3 style={{textAlign: "center"}}>Social Crawler Expertise App</h3>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col">
                {dropdownAppName}
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <h5 style={{textAlign: "center"}}>Expert Name</h5><br/>
                        <p style={{color: "red"}}>{errorExperName}</p>
                        <input type="text" className="form-control" value={expertName} onChange={(e) => setExpertName(e.target.value)}></input>
                    </div>
                    <br/>
                    <div style={{textAlign: "center"}}>
                        <button type="submit" className="btn btn-primary">Start</button>
                    </div>
                    <br/>
                    <a href="https://colab.research.google.com/drive/1BLUe5YjvrIffmfv9p4wDIXiCJzh5GPN9?usp=sharing">Readme</a>
                </form>
            </div>
          </div>
        </div>
    )
}

export default Home
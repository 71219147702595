import React , { useEffect , useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getDataTw, updateExpert, getCountConfirmByExpert } from '../service/axios';
// import qpm from 'query-params-mongo';
// import mongodb from 'mongodb';
function Confirm(){
    let location =  useLocation()
    let navigate = useNavigate();
    const [twData, setTwData] = useState([]);
    const [expertName, setExpertName] = useState("");
    const [titleAppName, setTitleAppName] = useState("");
    const [countConfirm, setCountConfirm] = useState("");
    const [showHtml, setShowHtml] = useState(true)
    useEffect(() => {  
        async function actionStart() {
            if(location.state === null){
                navigate('/');
            }
            else{
                setExpertName(location.state.expertName)
                setTitleAppName(location.state.titleAppName)
                await getDataApi(location.state.titleAppName, location.state.expertName)
            }
        }       
        actionStart()
    } , []);

    async function getDataApi(appName, expertName){
        let data_tw = await getDataTw(appName, expertName)
        if(data_tw.status === 200){
            if(data_tw.data.length > 0){
                setTwData(data_tw.data[0])
                setShowHtml(true)
            }
            else{
                setShowHtml(false)
            }
        }

        let data_count = await getCountConfirmByExpert(location.state.titleAppName, location.state.expertName)
        if(data_count.status === 200){
            setCountConfirm(data_count.count)
        }
    }

    async function alertConfirm(){
        let text = "Do you want to save?";
        if (window.confirm(text) === true) {
            return true
        } else {
          return false
        }
    }

    async function onConfirm(status){
        if(await alertConfirm() === false){
            return
        }
        await updateExpert(titleAppName, twData._id, expertName, status)
        await getDataApi(titleAppName, expertName)
    }

    return (
        <div className="container" style={{padding: '30px'}}>
            <br/>
            <h5 >Expert name : { expertName }</h5>
            <h5 >Count : { countConfirm }</h5>
            <br/>
            {
                (showHtml) ? 
                <>
                <div className="card">
                    <div className="card-header">
                        TWID : {twData._id}
                    </div>
                    <div className="card-body">
                        <p className="card-text">{twData.text}</p>
                    </div>
                </div>
                <br/>
                <div class="card">
                <div class="card-header">
                    คำถาม: ข้อความนี้เกี่ยวข้องตามนิยามหรือไม่
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">ใช่/เกี่ยวข้อง</li>
                    <li class="list-group-item">--- ใช่ - ข้อความนี้เป็นข้อมูลข่าวสาร</li>
                    <li class="list-group-item"><button type="button" onClick={() => onConfirm("YAX")} className="btn btn-outline-primary" style={{ width: '200px'}}>ใช่ - เป็นข้อมูลข่าวสาร (YAX)</button></li>
                    <li class="list-group-item">--- ใช่ - เป็นความคิดเห็นหรือความรู้สึก (ระบุความรู้สึก)</li>
                    <li class="list-group-item">
                        <button type="button" onClick={() => onConfirm("YBP")} className="btn btn-outline-success" style={{ width: '200px'}}>รู้สึกดี (YBP)</button>
                        <br/>
                        <button type="button" onClick={() => onConfirm("YBO")} className="btn btn-outline-secondary" style={{ width: '200px'}}>รู้สึกเฉยๆ (YBO)</button>
                        <br/>
                        <button type="button" onClick={() => onConfirm("YBN")} className="btn btn-outline-danger" style={{ width: '200px'}}>รู้สึกแย่ (YBN)</button>
                    </li>
                    <li class="list-group-item">ไม่ใช่/ไม่เกี่ยวข้อง</li>
                    <li class="list-group-item"><button type="button" onClick={() => onConfirm("NXX")} className="btn btn-danger" style={{ width: '200px'}}>ไม่ใช่ (NXX)</button></li>
                </ul>
                </div>
                <br/>
                <div style={{textAlign: "center"}}>
                    <button type="button" onClick={() => onConfirm()} className="btn btn-secondary" style={{ width: '100px'}}>ข้าม >></button>
                </div>
                </>
            : 
            <h3 style={{textAlign: "center"}}>Data Not Found !!!</h3>
            }
        </div>
    )
}

export default Confirm